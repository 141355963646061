import axios, {AxiosInstance, AxiosResponse} from "axios";
const baseUrl = 'https://api.reactvty.com' ;

export interface Channel {
    id: string;
    name: string;
    icon: string;
    order: number;
}

export interface Emission {
    id: string;
    channelId: string;
    name: string;
    startTime: string;
    endTime: string;
}

export interface ChannelEmissions {
    channelId: string;
    channelIcon: string;
    channelName: string;
    emissions: Array<Emission>;
}

export interface ChannelEmissionStats {
    emissionId: string;
    pieStats: Array<{token: string, count: number}>;
    barStats: Array<{franjaHoraria: string, values: Array<{token: string, count: number}>}>;
    numberOfSlots: number;
    startTime: string;
}


export interface Reaction {
    username: string;
    message: string;
    likes: number;
    token: string
}

export interface ChannelEmissionMessages {
    "topTen": Array<Reaction>,
    "selected" : Array<Reaction>,
}

export interface Token {
    id:string,
    name: string,
    color: string,
}

class EmissionService {
    private http: AxiosInstance;

    constructor() {
        console.log("Creating Axios")
        this.http = axios.create({
            baseURL: baseUrl,
            headers: {
                "Content-type": "application/json",
            },
        });
    }

    public fetchChannels() : Promise<AxiosResponse<{channels:Channel[]}>> {
        return this.http.get<{channels:Channel[]}>('/guide/channels');
    }

    public fetchTokens(): Promise<AxiosResponse<Token[]>> {
        return this.http.get<Token[]>('/backoffice/tokens');
    }

    public getEmissions(channelId: string, date:string): Promise<AxiosResponse<ChannelEmissions>> {
        return this.http.get<ChannelEmissions>(`/dashboard/channel/${channelId}/emissions?date=${date}`)
    }

    public getStats(channelId: string, emissionId:string): Promise<AxiosResponse<ChannelEmissionStats>> {
        return this.http.get<ChannelEmissionStats>(`/dashboard/channel/${channelId}/emissions/${emissionId}/stats`)
    }

    public getMessages(channelId: string, emissionId:string, slot: number): Promise<AxiosResponse<ChannelEmissionMessages>> {
        return this.http.get<ChannelEmissionMessages>(`/dashboard/channel/${channelId}/emissions/${emissionId}/messages?slot=${slot}`)
    }
}
// eslint-disable-next-line
export default new EmissionService();
