import './Header.scss';

export const Header = () => {

    return (
        <div className="header">
            <div className="header-icon">
                <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 223 84" fill="none">
                    <g clipPath="url(#clip0_19_351)">
                        <path d="M157.342 8.22123H144.088L150.94 1.34831L149.596 0L141.4 8.22123H133.693L125.497 0L124.153 1.34831L131.005 8.22123H117.751C111.647 8.22123 106.7 13.1837 106.7 19.3071V67.2905C106.7 73.4138 111.647 78.3763 117.751 78.3763H154.014L161.649 84L162.057 77.3166C165.801 75.5418 168.393 71.7188 168.393 67.2905V19.3071C168.393 13.1837 163.446 8.22123 157.342 8.22123Z" fill="#FABE47"/>
                        <path d="M0 19.0185H12.0319C20.446 19.0185 24.0638 22.9428 24.0638 30.94V34.1255C24.0638 39.4563 22.3655 42.8637 18.7499 44.3434V44.492C22.8099 45.7498 24.1389 49.6009 24.1389 55.4508V64.5594C24.1389 67.0772 24.2141 68.9274 25.0256 70.8529H16.7575C16.3152 69.5197 16.0189 68.7055 16.0189 64.484V55.0049C16.0189 50.1911 14.6169 48.636 10.9262 48.636H8.12217V70.8508H0V19.0185ZM11.0722 41.2332C14.0995 41.2332 15.9438 39.9 15.9438 35.7538V31.7542C15.9438 28.0517 14.6877 26.4234 11.8107 26.4234H8.12002V41.2332H11.0722Z" fill="white"/>
                        <path d="M27.9026 19.0185H50.047V26.4234H36.0227V40.1218H47.1678V47.5268H36.0227V63.448H50.047V70.8529H27.9026V19.0185Z" fill="white"/>
                        <path d="M59.1289 19.0185H70.1281L78.5422 70.8529H70.4222L68.9451 60.5597V60.7083H59.7172L58.2422 70.8529H50.7126L59.1268 19.0185H59.1289ZM67.9875 53.6738L64.3698 28.0517H64.2216L60.679 53.6738H67.9875Z" fill="white"/>
                        <path d="M79.9442 58.8581V31.0132C79.9442 23.016 84.0772 18.2775 91.9031 18.2775C99.729 18.2775 103.862 23.016 103.862 31.0132V36.4926H96.1842V30.4941C96.1842 27.0889 94.5611 25.6803 92.1242 25.6803C89.6874 25.6803 88.0642 27.0868 88.0642 30.4941V59.4483C88.0642 62.8557 89.6874 64.1868 92.1242 64.1868C94.5611 64.1868 96.1842 62.8535 96.1842 59.4483V51.5243H103.862V58.856C103.862 66.8532 99.729 71.5917 91.9031 71.5917C84.0772 71.5917 79.9442 66.8532 79.9442 58.856V58.8581Z" fill="white"/>
                        <path d="M128.443 24.1252V70.8529H121.356V24.1252H114.565V17.6831H135.234V24.1252H128.443Z" fill="white"/>
                        <path d="M160.406 17.6831L151.844 70.8529H143.429L134.867 17.6831H141.879L147.638 58.2637L153.396 17.6831H160.408H160.406Z" fill="white"/>
                        <path d="M178.636 26.4234H170.147V19.0185H195.243V26.4234H186.754V70.8529H178.634V26.4234H178.636Z" fill="white"/>
                        <path d="M205.064 48.7868L195.245 19.0185H203.883L209.418 38.0498H209.566L215.103 19.0185H223.002L213.184 48.7868V70.8529H205.064V48.7868Z" fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_19_351">
                            <rect width="223" height="84" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>
        </div>
    )
}
