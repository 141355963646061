import React, {useEffect, useState} from 'react';
import './App.scss';
import {Header} from "./components/Header/Header";
import EmissionService, {ChannelEmissions, Channel} from "./services/EmissionService";
import {ChannelAnalytics} from "./components/ChannelAnalytics/ChannelAnalytics";

const NAX_NUMBER_HISTORY_IN_DAYS = 5;

function App() {
    const [currentEmissions, setEmissions] = useState<ChannelEmissions>();
    const [isAnalyticsOpen, setAnalyticsOpen] = useState(true);
    const [selectedEmission, setSelectedEmission] = useState<string>();
    const [channels, setChannels] = useState<Channel[]>();

    const minDate = () => {
        const d = new Date();
        d.setDate(d.getDate()-NAX_NUMBER_HISTORY_IN_DAYS);
        return d;
    }

    const encodeDate = (date: string): string  => {
        return date.replaceAll('/', '%2F').replaceAll('-', '%2F');
    }

    const search = (e)  => {
        e.preventDefault();
        setAnalyticsOpen(false);

        let form = new FormData(e.target);
        let channel = form.get('channel') as string ?? ''
        let dateAsDate = (new Date(form.get('date')  as string ?? '')).toLocaleDateString()
        let date = encodeDate(dateAsDate);
        EmissionService.getEmissions(channel, date).then(res => {
            setEmissions({...res.data, channelId: channel})
        }).catch(err => console.error(err))
    }

    const selectEmission = (emissionId: string) => {
        setSelectedEmission(emissionId);
        setAnalyticsOpen(true);
    }

    useEffect(()=> {
        EmissionService.fetchChannels().then(channelsResp => {
            if(!channelsResp) {return;}
            let channels = channelsResp.data.channels;
            setChannels(channels);
            let date = encodeDate((new Date()).toLocaleDateString())
            let defaultChannel = channels.find(ch => ch.order === 1)!.id ??  '';
            EmissionService.getEmissions(defaultChannel, date).then(res => {
                setEmissions({...res.data, channelId: defaultChannel})
            }).catch(err => console.error(err))
        });
    }, [])

  return (
    <div className="app">
        <Header />
        <div className="search">

            <form className="toolbar" onSubmit={search}>
                <div className="filter-group">
                    <label>Selecciona una fecha</label>
                    <input className="input" id="datepicker" type="date" name="date"
                           defaultValue={(new Date()).toISOString().split('T')[0]}
                           min={minDate().toISOString().split('T')[0]}
                           max={(new Date()).toISOString().split('T')[0]}
                    />

                </div>

                <div className="filter-group">
                    <label>Selecciona una canal</label>
                    {channels &&
                        <select className="input" id="channel" name="channel" defaultValue={channels?.find(ch => ch.order === 1)!.id}>
                            {channels.map((ch, idx) =>
                            <option key={idx} value={ch.id}> {ch.name}</option>
                            )}
                        </select>
                    }
                </div>

                <input className="filter-btn" type='submit' value='Aplicar' >
                </input>

            </form>

            <div className="emissions">
                {currentEmissions &&<>
                    <div className="channel-icon-text">
                        <img className="icon" src={currentEmissions.channelIcon}
                             alt={`${currentEmissions.channelName} icon`}/>
                    </div>
                    <h2>{currentEmissions.channelName}</h2>
                </>}
                {currentEmissions && currentEmissions?.emissions.map((ce, idx)=>
                    <div key={idx} className={ `emission ${ce.id === selectedEmission ? 'emission-selected' : ''}`}
                         onClick={() => selectEmission(ce.id)}>
                        <header className="emission-header">
                            <div className="time">
                                {new Date(Date.parse(ce.startTime +'Z')).toLocaleTimeString()}
                            </div>
                            <h3>{ce.name}</h3>
                        </header>
                    </div>
                )}
            </div>

            {isAnalyticsOpen && selectedEmission && <div className="analytics">
                <div className="buttons">
                    <h2>Análisis el programa</h2>

                    <button onClick={() => setAnalyticsOpen(false)} >X</button>
                </div>
                <ChannelAnalytics channelId={currentEmissions?.channelId!} emissionId={selectedEmission!}/>
            </div>
            }
        </div>
    </div>
  )
}

export default App;
