import './ChannelAnalytics.scss'
import {Bar, Pie, getElementAtEvent} from 'react-chartjs-2';

import {ArcElement, BarElement, CategoryScale, Chart as ChartJS, LinearScale, Tooltip} from 'chart.js';
import {Reaction} from "../Reaction/Reaction";
import {useEffect, useRef, useState} from "react";
import EmissionService, {ChannelEmissionMessages, Token} from "../../services/EmissionService";
import {ChartJSOrUndefined} from "react-chartjs-2/dist/types";

ChartJS.register(ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
);

interface ChannelAnalyticsProps {
    emissionId: string ;
    channelId: string;
}

interface Dataset {
    label: string;
    data: number[];
    backgroundColor: string[];
    borderColor: string[];
    borderWidth: number;
}

interface GraphData {
    labels : string[];
    datasets: Dataset[]
}

const emptyPieStats: GraphData = {
    labels: [],
    datasets: [{
        label: 'Sin Datos',
        data: [1],
        backgroundColor: ['rgba(120, 130, 210, 0.5)'],
        borderColor: [
            'rgba(255, 255, 255, 1)',
        ],
        borderWidth: 2.5,
    }]
};

export const ChannelAnalytics = (props: ChannelAnalyticsProps) => {
    const pieRef = useRef<ChartJSOrUndefined<"pie", number[], string>>(null);
    const chartRef = useRef<ChartJSOrUndefined<"bar", (number | [number, number] | null)[], unknown>>(null);
    const [selectedSlot, setSelectedSlot] = useState<number>(0);
    const [selectedMessages, setSelectedMessages] = useState<ChannelEmissionMessages>();
    const [tokens, setTokens] = useState<Token[]>([]);
    const [pieStatsState, setPieStatsState] = useState<GraphData>();
    const [barStatsState, setBarStatsState] = useState<GraphData>();

    const onClick = (event) => {
        if(chartRef.current) {
            let elementInGroup = getElementAtEvent(chartRef.current, event);
            let groupIdx = elementInGroup.length > 0 ? elementInGroup.at(0)!.index : 0;
            setSelectedSlot(groupIdx);

            EmissionService.getMessages(props.channelId, props.emissionId, groupIdx)
            .then(messages => {
                setSelectedMessages(messages.data)
            })
        }
    }

    useEffect(()=> {
        EmissionService.fetchTokens().then(tokens => {
            setTokens(tokens.data);

            EmissionService.getStats(props.channelId, props.emissionId)
            .then(stats => {
                let statistics = stats.data;
                let pieStats: GraphData = {
                    labels: [],
                    datasets: [{
                        label: 'Reacciones',
                        data: [],
                        backgroundColor: [],
                        borderColor: [
                            'rgba(255, 255, 255, 1)',
                        ],
                        borderWidth: 2.5,
                    }]
                };
                for (const st of statistics.pieStats) {
                    pieStats = populatePieStat(st ,pieStats, tokens.data);
                }
                setPieStatsState(pieStats);
                let barStats: GraphData = {
                    labels: generateBarStatsLabels(statistics.startTime, statistics.numberOfSlots),
                    datasets: generateEmptyDatasets(tokens.data,statistics.numberOfSlots)
                };
                for (const slot of statistics.barStats) {
                    // Every slot
                    let time: string = slot.franjaHoraria.split('#')[1];
                    let slotNumber = barStats.labels.indexOf(time);

                    if(slotNumber < 0) { continue;}
                    for (const token of slot.values) {
                        let tokenByName = tokens.data.find(tk => tk.name === token.token)
                        let tokenById = tokens.data.find(tk => tk.id === token.token)
                        if(!tokenByName && !tokenById) {return;}
                        let resolvedToken = tokenByName? tokenByName : tokenById

                        let dataset = barStats.datasets.find(ds => ds.label === resolvedToken!.name);
                        if(dataset) {
                            dataset.data[slotNumber] = token.count;
                        }
                    }
                }

                setBarStatsState(barStats);
            })

            EmissionService.getMessages(props.channelId, props.emissionId, 0)
            .then(messages => {
                setSelectedMessages(messages.data);
            })
        })
    }, [props])

    const populatePieStat = (entry: {token: string, count: number}, acc:GraphData, tokens ):GraphData => {
        let tokenName = tokens.find(tk => tk.name === entry.token)
        let token;
        if(tokenName) {
            token = tokenName
        } else {
            let tokenNameById = tokens.find(tk => tk.id === entry.token)
            if (tokenNameById) {
                token = tokenNameById
            }
        }
        acc.labels.push(token.name);
        acc.datasets[0].data.push(entry.count);
        acc.datasets[0].backgroundColor.push(token.color);

        return acc;
    }

    const generateBarStatsLabels = (starTime: string, numberOfSlots: number) => {
        let labels: string[] = [];
        let asMilliseconds = new Date(starTime).getTime();
        for (let i = 0; i < numberOfSlots; i++) {
            let newDate = new Date(asMilliseconds + i*5*60000);
            let hours = `${newDate.getHours()}`.padStart(2, "0")
            let mins = `${newDate.getMinutes()}`.padStart(2, "0")
            labels.push(`${hours}:${mins}`);
        }
        return labels;
    }

    const generateEmptyDatasets = (tokens : Token[], numberOfSlots: number) => {
        let datasets: Dataset[] = []
        let data = Array.from({length: numberOfSlots}, (_, i) => 0)
        for (const token of tokens) {
            datasets.push({
                label: token.name,
                data: [...data],
                backgroundColor: [token.color],
                borderWidth: 0,
                borderColor: []
            })
        }
        return datasets;
    }

    const convertTokenIntoColor = (token: string): string  => {
        let obj = tokens.find(t => t.name === token)
        return obj ? obj.color : 'grey';
    }

    function getPositionAtCenter(element) {
        return {
            x: element.clientLeft + element.clientWidth / 2,
            y: element.clientTop + element.clientHeight / 2
        };
    }

    function getDistanceBetweenElements(a, b) {
        const aPosition = getPositionAtCenter(a);
        const bPosition = getPositionAtCenter(b);
        return Math.sqrt(
            Math.pow(aPosition.x - bPosition.x, 2) +
            Math.pow(aPosition.y - bPosition.y, 2)
        );
    }

    return (
        <div className="main">
            <div className="graphs">
                {pieStatsState && <Pie
                    ref={pieRef}
                    id="pie"
                    data={pieStatsState.labels.length === 0 ?  emptyPieStats : pieStatsState}
                    options={{
                        responsive: false,
                        rotation: -90,
                        circumference: 180,

                    }}
                />}
                {barStatsState && <Bar
                    ref={chartRef}
                    onClick={onClick}
                    id="bar"
                    data={barStatsState}
                    options={{

                        responsive: true,
                        scales: {
                            x: {
                                ticks: {
                                    display: false,
                                },
                                grid: {
                                    // lineWidth: 1
                                    display: false
                                },
                                border: {
                                    color: 'white',
                                }
                            },
                            y: {
                                ticks: {
                                    display: false,
                                },
                                grid: {
                                    display: false
                                },
                                border: {
                                    color: 'white',
                                },

                            }

                        }
                    }}/>
                }
                {barStatsState && pieRef.current && chartRef.current &&
                    <div className="slot-selector"
                         style={{
                             left:`${selectedSlot * (chartRef.current.width/ barStatsState?.labels.length)  +  pieRef.current?.ctx.canvas.offsetWidth + getDistanceBetweenElements(pieRef.current?.ctx.canvas, chartRef.current?.ctx.canvas)/2}px`,
                             width: `${chartRef.current.width/ barStatsState?.labels.length}px`
                    }}></div>}

            </div>

            <div className="data">

                <div className="remarked box">
                    <div className="reactions" >
                        <p>Más Likes</p>
                        {selectedMessages && selectedMessages?.topTen && selectedMessages.topTen.map((msg, idx) =>
                            (
                                <Reaction key={idx} reaction={{
                                    username: msg.username,
                                    message: msg.message,
                                    likes: msg.likes,
                                }}
                                          color={convertTokenIntoColor(msg.token)}
                                />
                            )
                        )}
                    </div>
                </div>

                <div className="selected box">
                    <p>En selección </p>
                    <div className="reactions" >
                        {selectedMessages && selectedMessages?.selected && selectedMessages.selected.map((msg, idx) =>
                            (
                                <Reaction  key={idx} reaction={{
                                    username: msg.username,
                                    message: msg.message,
                                    likes: msg.likes,
                                }}
                                          color={convertTokenIntoColor(msg.token)}
                                />
                            )
                        )}
                    </div>
                </div>

            </div>

        </div>
    )
}
